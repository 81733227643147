@import "../f-variables";
@import "../f-mixin";
.p-sec01 {
  background: url(../../images/mv.jpg) center no-repeat;
  background-size: cover;
  padding: 50px 0;
  position: relative;
  @include media(sm) {
    @include media(min) {
      padding: 30px 0;
    }
  }
  &:before {
  }
  &__textarea {
    text-align: center;
    padding: 50px 20px;
    position: relative;
    width: 600px;
    max-width: 100%;
    margin: 0 auto;
    @include media(sm) {
      padding: 30px 20px;
      width: 90%;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($color: #000000, $alpha: 0.6);
      transform: skew(-10deg);
      transform-origin: center;
      @include media(min) {
        transform: skew(-5deg);
      }
    }
    .inner {
      position: relative;
      z-index: 1;
    }
    .title {
      color: white;
      font-size: 95px;
      font-weight: bold;
      letter-spacing: 0.1em;
      @include media(sm) {
        font-size: 50px;
      }
      span {
        color: $main;
      }
    }
    .text {
      color: white;
      font-size: 18px;
      line-height: 1.5;
      margin: 20px 0 40px;
      @include media(sm) {
        font-size: 16px;
        margin: 10px 0 20px;
      }
      span {
        color: $main;
      }
    }
    .btn {
    }
  }
}
.p-sec02 {
  margin-top: 100px;
  @include media(sm) {
    margin-top: 50px;
  }
  &__list {
    li {
      border-bottom: 1px solid #cccccc;
      padding-bottom: 10px;
      + li {
        padding-top: 15px;
      }
      a {
        display: block;
        .date {
          font-size: 13px;
          color: $sub;
          margin-bottom: 5px;
          @include media(sm) {
            font-size: 12px;
          }
          i {
            margin-left: 5px;
          }
        }
        .title {
          font-size: 15px;
          line-height: 2;
          @include media(sm) {
            font-size: 13px;
          }
        }
      }
    }
  }
  &__btn {
    text-align: right;
    margin-top: 50px;
    @include media(sm) {
      margin-top: 30px;
    }
  }
}
.p-sec03 {
  margin-top: 100px;
  padding-bottom: 50px;
  @include media(sm) {
    margin-top: 50px;
    padding-bottom: 25px;
  }
  &__text {
    font-size: 15px;
    line-height: 2;
    margin-bottom: 50px;
    a {
      color: $link;
      text-decoration: underline;
    }
    @include media(sm) {
      font-size: 13px;
      margin-bottom: 30px;
    }
  }
  &__cat {
    + .p-sec03__cat {
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: -30px;
    justify-content: space-between;
    li {
      width: 48%;
      margin-top: 30px;
      @include media(min) {
        width: 100%;
      }
      &.is-end {
        opacity: 0.4;
      }
      // @include media(min) {
      //   &:nth-child(3) {
      //     margin-left: auto;
      //   }
      //   &:nth-child(4) {
      //     margin-right: auto;
      //   }
      //   &:nth-child(5) {
      //     margin-left: auto;
      //   }
      // }
      a {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        .image {
          background-color: #fff;
          position: relative;
          border: 1px solid #eaeaea;
          // aspect-ratio: 5/7;
          width: 35%;
          padding-top: calc(140% * 0.35);
          position: relative;
          img {
            position: absolute;
            top: 0;
            left: 0;
          }
          @include media(sm) {
          }
          .cat {
            position: absolute;
            bottom: 0;
            left: 0;
            color: white;
            padding: 5px;
            font-size: 14px;
            @include media(sm) {
              font-size: 12px;
            }
            &:nth-of-type(n + 2) {
              display: none;
            }
            &--green {
              background: $main;
            }
            &--orange {
              background: $sub;
            }
            &--blue {
              background: $sub2;
            }
            &--pink {
              background: $sub3;
            }
          }
        }
        .textarea {
          width: 60%;
          .meta01 {
            color: #5f5f5f;
            font-size: 14px;
            line-height: 1.4;
            margin: 10px 0;
            @include media(sm) {
              font-size: 12px;
            }
          }
          .meta02 {
            color: #5f5f5f;
            font-size: 14px;
            line-height: 1.4;
            margin: 10px 0;
            @include media(sm) {
              font-size: 12px;
            }
          }
          .title {
            font-size: 18px;
            line-height: 1.6;
            font-weight: bold;
            margin-bottom: 10px;
            @include media(sm) {
              font-size: 16px;
            }
          }
          .desc {
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            width: 100%;
            overflow: hidden;
            font-size: 14px;
            line-height: 1.6;
            color: #5f5f5f;
            @include media(sm) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  &__btn {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 40px;
    @include media(sm) {
      margin-top: 20px;
    }
    &--mb {
      margin-bottom: 100px;
      @include media(sm) {
        margin-bottom: 50px;
      }
    }
    a {
      margin-top: 10px;
      + a {
        margin-left: 30px;
        @include media(sm) {
          margin-left: 20px;
        }
      }
    }
  }
}
.p-sec04 {
  padding-top: 50px;
  @include media(sm) {
    padding-top: 25px;
  }
  &__intro {
    font-size: 15px;
    line-height: 2;
    margin-bottom: 50px;
    @include media(sm) {
      font-size: 13px;
      margin-bottom: 30px;
    }
  }
  &-slider {
    position: relative;
    background-color: #f5f5f5;
    overflow: hidden;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: #fff;
      height: 115px;
    }
    &__link {
      display: flex;
      align-items: flex-end;
      padding-bottom: 20px;
      .image {
        width: 144px;
        flex-shrink: 0;
        border: 1px solid #eaeaea;
        @include media(sm) {
          width: 100px;
        }
      }
      .textarea {
        position: relative;
        margin-left: 29px;
        @include media(sm) {
          margin-left: 10px;
        }
        &:before {
          content: "";
          position: absolute;
          bottom: -20px;
          left: -80px;
          background-color: #fff;
          width: 190px;
          height: 117px;
          z-index: -1;
        }
        .end {
          margin-bottom: 10px;
          font-size: 14px;
          color: #bbb;
          @include media(sm) {
            font-size: 12px;
          }
        }
        .date {
          color: $sub;
          font-size: 14px;
          margin-bottom: 10px;
          @include media(sm) {
            font-size: 12px;
          }
          i {
            margin-left: 5px;
          }
        }
        .title {
          font-size: 15px;
          line-height: 1.5;
          font-weight: bold;
          @include media(sm) {
            font-size: 13px;
          }
        }
      }
    }
    .swiper {
      //overflow: visible;
    }
    .swiper-slide {
      width: 400px;
      @include media(sm) {
        width: 250px;
      }
    }
  }
  &-footer {
    display: flex;
    align-items: center;
    padding: 30px 0;
    @include media(sm) {
      padding: 30px 0 60px;
      justify-content: space-between;
    }
    &__arrow {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      margin-right: 50px;
      @include media(sm) {
        margin-right: 20px;
      }
      .swiper-button-next,
      .swiper-button-prev {
        position: static;
        color: black;
        margin-top: 0;
        width: 20px;
        height: 20px;
        &:after {
          font-size: 20px;
        }
      }
      .swiper-button-prev {
        margin-right: 50px;
        @include media(sm) {
          margin-right: 20px;
        }
      }
    }
    &__scroll {
      width: 294px;
      @include media(sm) {
        width: 100%;
      }
      .swiper-scrollbar {
        width: 100%;
        height: 4px;
        border-radius: 0;
        @include media(sm) {
        }
      }
      .swiper-scrollbar-drag {
        background: $main;
        height: 10px;
        border-radius: 0;
        top: -3px;
      }
    }
    &__btn {
    }
  }
  &__btn {
    transform: translateY(-50%);
    text-align: right;
  }
}
